<template>
	<div style="height: 100%; max-width: 600px; margin: 0 auto; position: relative;" id="mybox">
		<div v-if="step == 0" style=" background-size: 100% auto ; background-image: url(./img/wj07.jpg);">
			<div style="height: 30px;"></div>
			<div v-for="(wj, idx) in DataList" :key="idx" class="wj">


				<div class="tm" v-for="(tm, tmidx) in wj.tm_list" v-if="tm.show">
					<div class="tmlabel">
						{{ tmidx + 1 }}/{{ wj.tm_list.length }}
					</div>
					<div style="margin-bottom: 10px; color: #45488d; padding: 0 15px; font-weight: bold;">
						<div v-if="tm.tm_type == 'tiankong'">
							<inputdom :tm="tm" :tmidx="tmidx" @setInput="setInput"></inputdom>

						</div>
						<div v-else>
							{{ tm.tm_title }}
						</div>
					</div>
					<div v-if="tm.tm_type == 'radio_normal'">
						<div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '']"
							@click="chooseXX(tm, xx)">

							{{ xx.title }}

							<div style="float: right;">
								<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
								<i v-else class="iconfont">&#xe619;</i>
							</div>


						</div>
					</div>

					<div v-if="tm.tm_type == 'checkbox_normal'">
						<div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '']"
							@click="chooseXXMore(tm, xx)">

							{{ xx.title }}
							<div style="float: right;">
								<i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
								<i v-else class="iconfont">&#xe622;</i>
							</div>

						</div>
					</div>

					<div v-if="tm.tm_type == 'wenda'" style="width: 94%;margin: 0 auto;">
						<textarea
							style="border: 1px solid #0f9fff; background-color: #eaf7ff; width: 90%; border-radius: 5px; padding:3% 10px; outline: none; margin-bottom: 10px;"
							placeholder="请输入您的回答"></textarea>
					</div>

					<div v-if="tm.tm_type == 'radio_image'">
						<div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '']"
							@click="chooseXX(tm, xx)">
							<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
							<i v-else class="iconfont">&#xe619;</i>


							<img :src="xx.value" style="width: 80%;" />
						</div>
					</div>
					<div v-if="tm.tm_type == 'checkbox_image'">
						<div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '']"
							@click="chooseXXMore(tm, xx)">
							<i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
							<i v-else class="iconfont">&#xe622;</i>

							<img :src="xx.value" style="width: 80%;" />

						</div>
					</div>
				</div>

				<div style=" padding: 15px; padding-bottom: 200px;">
					<div class="btntj" v-if="!joined && !expired" @click="confirmTJ">提交</div>
				</div>

				<div style="height: 15px;"></div>


			</div>

		</div>

		<div v-if="showUser"
			style="position: absolute; width: 100%; height: 100%; top: 0; background: rgba(0,0,0,0.5);">
			<div style="height: 10vw;">

			</div>
			<div style="width: 70%; margin: 0 auto;">
				<img src="../../../public/img/wj_wsxx.png" style="width: 100%; vertical-align: middle;" />
			</div>
			<div
				style="width: 70%; margin: 0 auto; background-color: #fff; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">姓名： <i style="color: orangered;">*</i> </span>
					<input type="text" v-model="userMore.stu_name"
						style="width: 50%; outline: none; border: 0; text-align: right; float: right;"
						placeholder="请输入姓名" @input="checkUsername" @blur="checkUsernameok" />
				</div>
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">毕业时间： <i style="color: orangered;">*</i></span>
					<div style="float: right; position: relative; width: 50%; text-align: right;">

						<div>
							<input type="text" :value="userMore.gyear"
								style="width: 100%; outline: none; border: 0; text-align: right; float: right;"
								placeholder="请选择毕业年份" />
						</div>
						<div style="position: absolute; top: 0; width: 100%; height: 100%;">
							<select v-model="userMore.gyear" style="opacity: 0; width: 100%;" @change="changeYear">
								<option v-for="(y, i) in YearList" :key="i" :value="y.gyear">{{ y.gyear }}年</option>

							</select>
						</div>
					</div>
				</div>
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">班级： <i style="color: orangered;">*</i></span>
					<div style="float: right; position: relative; width: 50%; text-align: right;">

						<div>
							<input type="text" :value="userMore.class_name"
								style="width: 100%; outline: none; border: 0; text-align: right; float: right;"
								placeholder="请选择班级" />
						</div>
						<div style="position: absolute; top: 0; width: 100%; height: 100%;">
							<select v-model="userMore.class_name" style="opacity: 0; width: 100%;">
								<option v-for="(y, i) in ClassList" :key="i" :value="y.class_name">{{ y.class_name }}
								</option>

							</select>
						</div>
					</div>
				</div>
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">手机号： <i style="color: orangered;">*</i></span>
					<input type="number" v-model="userMore.phone"
						style="width: 50%; outline: none; border: 0; text-align: right; float: right;"
						placeholder="请输入11位手机号" />
				</div>
				<div style="margin: 30px auto; width: 60%; padding-bottom: 30px;" @click="confirmChoujiang">
					<img src="../../../public/img/btn_usertj.png" style="width: 100%;" />
				</div>
			</div>



		</div>


		<div v-if="step >= 1"
			style="position: relative; background-image: url(./img/wj_zp2.jpg); background-size: 100% auto; background-position: top; height: 100%;">
			<div style="height: 32.5vw;">

			</div>
			<div style="width: 67%; margin: 0 auto; position: relative;">
				<div id="myzp">
					<img src="../../../public/img/wj_zp2.png" style="width: 100%; vertical-align: middle;" />
				</div>
				<div style="width: 15%; margin: 0 auto; position: absolute; left: 42.2%; top: 21%;">
					<img src="../../../public/img/wj_point.png" style="width: 100%; vertical-align: middle;" />
				</div>
			</div>


			<div style="height: 200px;"></div>
			<div style=" padding: 15px; padding-bottom: 200px;">
				<div class="btntj" v-if="step == 1" style="background-color: #de3835;" @click="choujiang">点击抽奖</div>
			</div>

			<div v-if="step == 4"
				style="position: absolute; width: 100%; height: 100%; top: 0; background-color: rgba(0,0,0,0.5);">
				<div style="height: 10%;">

				</div>
				<div style="position: relative; width: 100%; margin: 0 auto;">
					<img src="../../../public/img/zp_result.png" style="width: 100%; vertical-align: middle;" />
					<div style="position: absolute; top: 50%; width: 60%; left: 20%; text-align: center; color: #fff;">
						恭喜您获得{{ prize_value }}元红包，已自动发放，请注意查收！
					</div>
					<div style="position: absolute; bottom: 20px; width: 100%;">
						<div @click="step = 5"
							style="width: 40%; background-color: #f8e437; color: #f33b3d; border-radius: 19px; margin: 0 auto; border: 4px solid #fff; text-align: center; line-height: 30px;">
							喊同学来抽奖
						</div>
					</div>
				</div>
			</div>
			<div v-if="step == 5" @click="step = 4"
				style="position: absolute; width: 100%; height: 100%; top: 0; background-color: rgba(0,0,0,0.7); color: #fff; text-align: right; font-size: 20px;">
				<img src="../../../public/img/share_tip.png" style="width: 50px;" />
				<div style="padding-right: 20px;">
					请点击右上角<br>分享给好友或朋友圈吧
				</div>
			</div>




		</div>
		<div id="toast" class="toast">
			请选择完整
		</div>

		<div id="alert" class="alert flexCenter">
			<div class="alertbox">
				<div class="alerttitle">提示</div>
				<div id="alertcontent" class="alertcontent"></div>
				<div class="alertbutton" @click="hideAlert">确定</div>
			</div>
		</div>


		<div id="alert2" class="alert flexCenter">
			<div class="alertbox">
				<div class="alerttitle">提示</div>
				<div class="alertcontent">确认提交？</div>
				<div class="alertbutton" @click="submitData">确定</div>
				<div style="text-align: center; color: #888; margin-top: 20px;" @click="cancelSubmit">取消</div>
			</div>
		</div>


	</div>
</template>

<script>
import inputdom from "./inputDom.vue"
export default {
	components: {
		inputdom
	},
	data() {
		return {
			plan: {
				pmid: 0,
				id: 0,
				juid: 0,
				utype: "",
				ftype: ""
			},
			step: 0,
			tempid: 0,
			DataList: [],
			joined: false,
			viewResult: false,
			expired: false, //是否过期
			showUser: false,
			userMore: {
				class_name: "",
				phone: "",
				gyear: "",
				stu_name: ""
			},
			YearList: [],
			ClassList: [],
			cj_ing: false,
			prize_value: "",
			submitWjing: false

		}
	},
	mounted() {

		if (!localStorage.getItem("ckeckedXX")) {
			alert("请先选择工作状态")
			setTimeout(() => {
				this.$router.push("/p/" + this.$route.query.plan)
			}, 2000)
		}
		if (localStorage.getItem("userinfo")) {
			this.userMore = JSON.parse(localStorage.getItem("userinfo"))
			this.userMore.openid = sessionStorage.getItem("openid")
		}


		this.tempid = this.$route.query.id

		if (this.$route.query.plan) {

			let ids = this.$route.query.plan
			let d = new Date();
			let plan = {
				pmid: 3,
				plan_id: this.$route.query.plan,
				utype: 's',
				ftype: 'm',
				juid: d.getDate() + "" + parseInt(Math.random() * 10000000)

			}



			plan.temp_id = this.tempid
			this.plan = plan

		}
		if (this.tempid) {
			this.getList();
		}

		//this.getYears()

		//检查是否做了题但是没提交班级
		// if (this.$route.query.step) {
		// 	this.showUser = true
		// 	$("#mybox").css("overflow", "hidden")
		// }

	},
	methods: {
		getYears() {
			this.$http.post("/api/h5_ivst_year").then(res => {
				this.YearList = res.data


			})
		},
		checkUsername() {
			this.$http.post("/api/h5_stuname_check", { stu_name: this.userMore.stu_name }).then(res => {
				if (res.data.msg) {
					this.YearList = []
					this.userMore.gyear = "";
					this.userMore.class_name = "";
				} else {
					this.YearList = res.data
					if (this.YearList.length == 1) {
						this.userMore.gyear = this.YearList[0].gyear
						this.ClassList = this.YearList[0].classes
						if (this.ClassList.length == 1) {
							this.userMore.class_name = this.ClassList[0].class_name
						} else {
							this.userMore.class_name = "";
						}
					} else {
						this.userMore.gyear = "";
					}
				}
			})
		},
		checkUsernameok() {
			if (this.YearList.length == 0) {
				this.showToast("未找到该学生，请检查姓名是否正确！")
			}
		},
		changeYear() {
			this.userMore.class_name = "";
			for (let item of this.YearList) {
				if (item.gyear == this.userMore.gyear) {
					this.ClassList = item.classes
					if (this.ClassList.length == 1) {
						this.userMore.class_name = this.ClassList[0].class_name
					}
				}
			}
			//this.getClass()

		},
		getClass() {
			this.$http.post("/api/h5_stu_classes", {
				year: this.userMore.gyear
			}).then(res => {
				this.ClassList = res.data
			})
		},
		setInput(idx, value) {
			let tm = this.DataList[0].tm_list[idx]
			this.DataList[0].tm_list[idx].answer = value
			if (tm.id == 336) {//选择江苏省
				if (value.indexOf("江苏") >= 0) {
					for (let timu of this.DataList[0].tm_list) {
						if (timu.id == 333) {
							timu.show = true
						}
						if (timu.id == 347) {
							timu.show = false
						}
					}
				} else {//选省外
					for (let timu of this.DataList[0].tm_list) {
						if (timu.id == 333) {
							timu.show = false
						}
						if (timu.id == 347) {
							timu.show = true
						}
					}
				}

			}


		},
		getOrderList(idx) {
			let data = {
				0: "A",
				1: "B",
				2: "C",
				3: "D",
				4: "E",
				5: "F",
				6: "G",
				7: "H",
				8: "I",
				9: "J"
			}
			return ""

		},
		getList(page) {
			let joined = window.localStorage.getItem("joined")
			if (joined) {
				this.joined = true
			}
			this.$http.post("api/h5_ivst_one", this.plan).then(
				res => {
					let tmarr = [];
					res.data.answers = [];
					if (res.data.answers && res.data.answers.length > 0) {
						this.joined = true
						let len = res.data.tm_list.length - 1
						for (let index = 0; index <= len; index++) {
							let item = res.data.tm_list[index]
							if (res.data.answers[0].tm) {
								let answers = JSON.parse(res.data.answers[0].tm)

								for (let ans of answers) {

									if (item.id == ans.tmid) {
										if (ans.answers) {
											let xxobj = JSON.parse(ans.answers)
											for (let itemxx in xxobj) {
												// console.log(204,itemxx,xxobj[itemxx])
												if (item.tm_type == "tiankong") {
													// item.tm_title = item.tm_title.replace(/___/g,
													// 	"<input type='text' />").replace(/_/g,
													// 	`${xxobj[itemxx]}`)
												} else if (item.tm_type == "wenda") {
													// console.log(209,index,`${xxobj[itemxx]}`)
													setTimeout(function () {
														// console.log(217,$(".tm").eq(index).find("textarea").val("Dolly Duck"))
														$(".tm").eq(index).find("textarea").val(
															`${xxobj[itemxx]}`)
														$(".tm").eq(index).find("textarea").attr(
															"disabled", true);
													}, 200)
												} else {
													if (item.tm_content && typeof (item.tm_content) == "string") {
														try {
															item.tm_content = JSON.parse(item.tm_content)
															for (let xx of item.tm_content) {
																xx.choosed = false
																if (xx.xxid == itemxx) {
																	xx.choosed = true
																}
															}
														} catch (err) {
															console.log(227, item.tm_content)
															console.log(228, err)
														}
													}
												}
											}
										}
									}
								}
							}
						}

					} else {
						let tm0 = res.data.tm_list[0];
						tm0.tm_content = JSON.parse(tm0.tm_content)
						let checkxx = localStorage.getItem("ckeckedXX");
						let jobtype = "";
						for (let job of tm0.tm_content) {
							if (job.xxid == checkxx) {
								jobtype = job.title
							}
						}


						for (let item of res.data.tm_list) {

							if (item.tm_title.indexOf(jobtype) > 0 || item.tm_title.indexOf("所有人") > 0) {
								item.tm_title = item.tm_title.split("（")[0].split("注：")[0]
								if (item.tm_content) {
									item.tm_content = JSON.parse(item.tm_content)
									for (let xx of item.tm_content) {
										xx.choosed = false
									}
								}
								if (item.tm_type == "tiankong") {


									// item.tm_title = item.tm_title.replace(/___/g, "<input type='text' />").replace(
									// 	/_/g,
									// 	'')
								}
								item.show = true
								tmarr.push(item)
							}

						}
					}
					res.data.tm_list = tmarr
					this.DataList = [res.data]
					this.plan.ivst_id = res.data.id
					if (this.joined) {
						this.showToast("您已参与过，请勿重复参与！")
					}

				})
		},
		chooseXX(tm, xx) {
			if (this.joined) {
				return
			}
			let tmLimits = []; //找出所有不可答的题目
			for (let txx of tm.tm_content) {
				txx.choosed = false;
				let tmLimit = txx.tmLimit.split(",")
				for (let tl of tmLimit) {
					if (tl) {
						tmLimits.push(tl)
					}
				}
			}
			console.log(tmLimits)
			xx.choosed = true;

			for (let tl of tmLimits) {
				for (let tm of this.DataList[0].tm_list) {
					if (tm.id == tl) {
						tm.show = true
					}
				}
			}

			if (xx.tmLimit) {
				//先将所有重置为可答，然后将此选项的重置为不可答

				for (let tm of this.DataList[0].tm_list) {
					let tmLimit = xx.tmLimit.split(",")

					for (let tl of tmLimit) {
						if (tm.id == tl) {
							tm.show = false

						}
					}

				}
			}


		},
		chooseXXMore(tm, xx) { //多选


			if (this.joined) {
				return
			}
			xx.choosed = !xx.choosed;
			if (xx.choosed) {
				//检查是否和其他选项不兼容
				if (xx.xxLimit) {

					let xxLimits = xx.xxLimit.split(",")
					for (let xl of xxLimits) {
						if (xl) {
							let idx = parseInt(xl) - 1
							tm.tm_content[idx].choosed = false
						}
					}

				}
				//检查是否大于选择项目
				let ckdcount = 0;
				for (let x of tm.tm_content) {
					if (x.choosed) {
						ckdcount++
					}
				}
				console.log(ckdcount, tm.max_cnt)
				if (ckdcount > tm.max_cnt) {
					xx.choosed = false
					this.showToast("最多选择" + tm.max_cnt + "项")

				}
			}

			let tmLimits = []; //找出所有不可答的题目
			for (let txx of tm.tm_content) {
				if (txx.tmLimit) {
					let tmLimit = txx.tmLimit.split(",")
					for (let tl of tmLimit) {
						if (tl) {
							tmLimits.push(tl)
						}
					}
				}
			}


			for (let tl of tmLimits) {
				for (let tm of this.DataList[0].tm_list) {
					if (tm.id == tl) {
						tm.show = true
					}
				}
			}
			let needLimit = [];
			for (let xx of tm.tm_content) {
				if (xx.choosed && xx.tmLimit) {
					let tmLimit = xx.tmLimit.split(",")
					for (let tl of tmLimit) {
						if (tl) {
							needLimit.push(tl)
						}
					}
				}
			}
			if (needLimit.length > 0) {
				//先将所有重置为可答，然后将此选项的重置为不可答

				for (let tm of this.DataList[0].tm_list) {
					for (let tl of needLimit) {
						if (tm.id == tl) {
							tm.show = false
						}
					}
				}
			}





		},
		showToast(msg) {
			$("#toast").text(msg).show().animate({
				opacity: 1
			}, 200, "linear", function () {
				setTimeout(() => {
					$("#toast").animate({
						opacity: 0
					}, "linear", 3000, function () {
						$("#toast").hide()
					})
				}, 2000)
			})
		},
		showAlert(msg) {
			$("#alertcontent").html(msg);
			$("#alert").css("display", "flex").animate({
				opacity: 1
			}, 200, "linear", function () {

			})
		},
		hideAlert() {
			$("#alert").animate({
				opacity: 0
			}, "linear", 3000, function () {
				$("#alert").hide()
			})
			if (this.viewResult) {
				//this.$router.push("/h5result/"+this.$route.params.id)
			}
		},
		showTJ() {
			this.$router.push("/h5result/" + this.$route.params.id)
		},
		submitData() {
			$("#alert2").animate({
				opacity: 0
			}, "linear", 3000, function () {
				$("#alert2").hide()
			})
			let total_value = 0;
			let total_score = 0;
			let resulttm = [];

			for (let wj of this.DataList) {
				let i = 1;
				//第一题
				if (localStorage.getItem("ckeckedXX")) {
					let result = {
						tmid: 0,
						answers: {},
						iscorrect: 0,
						score: 0
					}
					result.answers[localStorage.getItem("ckeckedXX")] = 1
					result.answers = JSON.stringify(result.answers)
					resulttm.push(result)
				}
				for (let tm of wj.tm_list) {

					let result = {
						tmid: tm.id,
						answers: {},
						iscorrect: 0,
						score: 0
					}
					if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {
						result.iscorrect = 1
						for (let xx of tm.tm_content) {

							if (!xx.choosed && xx.is_correct) {
								result.iscorrect = 0
							}
							if (xx.choosed && !xx.is_correct) {
								result.iscorrect = 0
							}
							if (xx.choosed) {
								result.answers[xx.xxid] = 1
								result.score = xx.score ? xx.score : 0
								total_score += xx.score ? xx.score : 0
							}
						}


					} else if (tm.tm_type == 'tiankong') {
						result.iscorrect = -1

						result.answers[0] = tm.answer

					} else if (tm.tm_type == 'wenda') {

						result.iscorrect = -1

						let answer = $(".tm").eq(i - 1).find("textarea").val()

						if (answer) {
							result.answers[0] = answer

						}


					}
					result.answers = JSON.stringify(result.answers)
					resulttm.push(result)
					i++;
				}
			}
			let usetime = 0;
			if (this.totalsecond >= 0) {
				usetime = this.DataList[0].minute * 60 - this.totalsecond
			}



			this.$http.post("api/h5_ivst_save", {
				plan: this.plan,
				answer: resulttm,
				score: total_score,
				openid: sessionStorage.getItem("openid"),
				input_json:JSON.stringify(this.userMore)

			}).then(res => {
				if (res.data.code == 200) {
					$(".wj").scrollTop(0)
					this.confirmChoujiang()
					$("#mybox").css("overflow", "hidden")


				} else if (res.data.code == 201) {
					this.showToast("您已参与过，请勿重复参与！")

				}
			})



		},

		confirmTJ() {
			if (this.submitWjing) {

				return
			} else {
				this.submitWjing = true
				setTimeout(() => {
					this.submitWjing = false
				}, 3000)
			}



			//检查是否有未做的提
			for (let wj of this.DataList) {
				let i = 1;
				for (let tm of wj.tm_list) {
					if (tm.show) {
						if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {
							let choosed = false;
							for (let xx of tm.tm_content) {
								if (xx.choosed) {
									choosed = true;
									break;
								}
							}
							if (!choosed) {
								this.showToast("请选择第" + i + "题目")

								$(".wj").scrollTop(0)
								$(".wj").scrollTop($(".tm").eq(i - 1).offset().top)
								return
							}
						} else if (tm.tm_type == 'tiankong') {
							if (!tm.answer) {
								this.showToast("请输入填空第" + i + "题目")
								$(".wj").scrollTop(0)
								$(".wj").scrollTop($(".tm").eq(i - 1).offset().top)
								return
							}

						} else if (tm.tm_type == 'wenda') {

							if (!$(".tm").eq(i - 1).find("textarea").val()) {
								this.showToast("请输入第" + i + "题目")

								$(".wj").scrollTop(0)
								$(".wj").scrollTop($(".tm").eq(i - 1).offset().top)
								return
							}

						}
					}
					i++;
				}


			}
			this.submitData()


		},
		confirmChoujiang() {
			if (this.joined) {
				return
			}
			if (this.userMore.stu_name && this.userMore.gyear && this.userMore.class_name) {
				this.joined = true

				this.$http.post("/api/h5_ivst_check_user", this.userMore).then(res => {
					if (res.data.code == 200) {
						this.showToast("您的问卷已提交，请开始抽奖！")
						this.step = 1
						this.$nextTick(() => {
							$(".wj").scrollTop(0)
						})
					} else {
						this.showToast("您的问卷已提交，请开始抽奖！")
						this.step = 1
						this.$nextTick(() => {
							$(".wj").scrollTop(0)
						})
					}
				})
			}





			//获取抽奖结果


		},
		cancelSubmit() {


			$("#alert2").animate({
				opacity: 0
			}, 200, "linear", function () {
				$("#alert2").hide()
			})
		},
		choujiang() {
			if (!this.cj_ing) {
				this.cj_ing = true
			} else {
				return
			}
			this.$http.post("/api/h5_ivst_choujiang", {
				plan_id: this.plan.plan_id,
				openid: sessionStorage.getItem("openid")
			}).then(res => {

				if (res.data.prize_value) {
					let prize_value = res.data.prize_value;
					let _deg = 0;
					if (prize_value == 1) {
						_deg = 68
					} else if (prize_value == 5) {
						_deg = 20
					} else if (prize_value == 10) {
						_deg = 340
					} else if (prize_value == 20) {
						_deg = 245
					} else if (prize_value == 30) {
						_deg = 200
					} else if (prize_value == 50) {
						_deg = 165
					} else if (prize_value == 100) {
						_deg = 120
					}
					this.prize_value = prize_value
					this.step = 3;
					let deg = 720 * 2 + _deg
					$("#myzp").animate({
						transform: "rotateZ(" + deg + "deg)"
					}, 5000, "ease-in-out", () => {
						this.step = 4


						this.$http.post("/api/h5_ivst_send_prize", {
							plan_id: this.plan.plan_id,
							openid: sessionStorage.getItem("openid")
						}).then(res => {

						})

					})
				} else {
					alert(res.data.msg)
				}
			})

		}
	}
}
</script>

<style scoped>
.wj {
	padding: 0 15px;
	word-break: break-all;

}

.tm {
	border: 3px solid #0f9fff;
	margin-bottom: 20px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 3px 1px 1px #2226bc;

}

.tmlabel {
	width: 80px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	background: linear-gradient(to right, #8bd0fd, #0f9fff);
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	margin: 10px 0 10px 0;
	margin-left: -3px;
	color: #fff;
}

.required {
	color: orangered;
}

.notrequired {
	color: #FFFFFF;
}

.xxitem {
	width: 80%;
	padding: 10px;
	border: 1px solid #f2f2f2;
	margin: 0 auto;
	margin-bottom: 10px;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;
	border-radius: 20px;
	background-color: #f2f2f2;
}

.choosed {
	color: #fff;
	border: 1px solid #007AFF;
	background-color: #007AFF;

}

.iconfont {
	display: inline-block;
	transform: translateY(2px);
	vertical-align: top;
}

.btntj {
	height: 40px;
	width: 60%;
	margin: 0 auto;
	border: 4px solid #fff;
	text-align: center;
	line-height: 40px;
	background: #007AFF;
	color: #FFFFFF;
	border-radius: 24px;
}

.toast {
	position: fixed;
	width: 70%;
	left: 15%;
	text-align: center;
	padding: 5px 0;
	border-radius: 4px;
	bottom: 50%;
	background: orangered;
	color: #FFFFFF;
	font-size: 14px;
	opacity: 0;
	display: none;
}

.alert {
	position: absolute;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
}

.alertbox {
	width: 80%;
	background: #FFFFFF;
	border-radius: 4px;
	padding: 10px;
	margin: 0 auto;
}

.alerttitle {
	font-size: 16px;
	text-align: center;
	border-bottom: 1px solid #f6f6f6;
	padding-bottom: 10px;
}

.alertcontent {
	padding: 20px 0;
	word-break: break-all;
	text-align: center;
}

.alertbutton {
	border-top: 1px solid #f6f6f6;
	padding: 10px;
	text-align: center;
	background-color: #007AFF;
	color: #fff;
	border-radius: 6px;
	cursor: pointer;

}

.tiankong {
	display: inline-block;
}

.tiankong>input[type='text'] {
	border: 0;
	outline: none;
	width: 100px;
	border-bottom: 1px solid #888;
	text-align: center;
}
</style>
