<template>
	<div style="display: inline-block;">
		<div  style="display: inline-block; " v-for="(t,i) in titles" :key="i">
			<div v-if="t.type=='str'">
				{{t.value}}
			</div>
			<div v-if="t.type=='dom'">
				
				<div v-if="t.value=='city'">
					<el-cascader :ref="'city'+domid"
						v-model="inputCode" size="small" style="width:200px;" @change="selectCity"
						:props="treeProps" clearable :options="areas" placeholder="请选择地区"></el-cascader>
				</div>
				<div v-if="t.value=='select'">
					<el-select
						v-model="inputValue" size="small" style="width:200px;" @change="getSelectItems"
						 placeholder="请选择">
						<el-option v-for="(item,dix) in selectItems" :key="dix" :label="item" :value="item">
							
						</el-option>
						 </el-select>
				</div>
				<div v-if="t.value=='date'">
					<el-date-picker size="small"
					      v-model="inputValue"
					      align="right"
					      type="date"
					      placeholder="选择日期"
					      value-format="yyyy-MM-dd" @change="getSelectItems" style="width:200px;">
					    </el-date-picker>
				</div>
				<div v-if="t.value=='input'">
					<el-input  v-model="inputValue" size="small" @input="getSelectItems"  placeholder="请输入" style="width:200px;"></el-input>
				</div>
				
				<div v-if="t.value=='number'">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" type="number" placeholder="请输入" style="width:100px;"></el-input>
				</div>
				
			</div>
		</div>
		
	</div>
</template>

<script>
	import area from "../../utils/arae_value.js"
	
		export default {
			props: ["tm", 'tmidx'],
			data() {
				return {
					titles: [],
					treeProps: {
						children: 'children',
						label: 'label',
						value: 'value',
						emitPath: false,
					},
					areas:[],
					selectItems:[],
					inputValue:"",
					inputCode:"",
					domid:parseInt(Math.random()*100000)
				}
			},
			mounted() {
				
				let tm_title = this.tm.tm_title.split("（")[0]
				let titles = []
				if(tm_title.indexOf("_${省市区}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${省市区}_")[0]});
					titles.push({type:"dom",value:"city"})
					titles.push({type:"str",value:tm_title.split("_${省市区}_")[1]})
				}else if (tm_title.indexOf("_${省市}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${省市}_")[0]});
					titles.push({type:"dom",value:"input"})
					
					titles.push({type:"str",value:tm_title.split("_${省市}_")[1]})
				}else if (tm_title.indexOf("_${现在时间}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${现在时间}_")[0]});
					titles.push({type:"dom",value:"date"})
					titles.push({type:"str",value:tm_title.split("_${现在时间}_")[1]})
					this.inputValue = this.getDateStr()
				}else if (tm_title.indexOf("_${选择时间}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${选择时间}_")[0]});
					titles.push({type:"dom",value:"date"})
					titles.push({type:"str",value:tm_title.split("_${选择时间}_")[1]})
				}else if (tm_title.indexOf("_${姓名}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${姓名}_")[0]});
					titles.push({type:"dom",value:"input"})
					titles.push({type:"str",value:tm_title.split("_${姓名}_")[1]})
				}else if (tm_title.indexOf("_${普通输入框}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${普通输入框}_")[0]});
					titles.push({type:"dom",value:"input"})
					titles.push({type:"str",value:tm_title.split("_${普通输入框}_")[1]})
				}else if (tm_title.indexOf("_${数字}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${数字}_")[0]});
					titles.push({type:"dom",value:"number"})
					titles.push({type:"str",value:tm_title.split("_${数字}_")[1]})
				}else if (tm_title.indexOf("_${下拉框}_")>=0){
					titles.push({type:"str",value:tm_title.split("_${下拉框}_")[0]});
					titles.push({type:"dom",value:"select"})
					titles.push({type:"str",value:tm_title.split("_${下拉框}_")[1]})
					this.selectItems = this.tm.selectItems.replace(/、/g,',').replace(/\r/g,',').replace(/，/g,',').split(",")
				}
				this.titles = titles
				this.areas = area
			},
			methods:{
				selectCity(e){
					
					for(let a of area){
						for(let b of a.children){
							for(let c of b.children){
								if(c.value == e){
									this.inputValue = a.label +","+b.label+","+c.label
									this.$emit("setInput",this.tmidx,this.inputValue)
									break
								}
							}
						}
					}
					
				},
				getSelectItems(){
					this.$emit("setInput",this.tmidx,this.inputValue)
				}
			}
			
		}
</script>

<style>
</style>
